import React from "react";
import { Grid } from "@material-ui/core";

// // import CardContents from "../components/Cards/CardContents";
import Footer from "../components/Footer/Footer";
// import WhyRegister from "../components/WhyRegister/Whyregister";
import AboutSchool from "../components/AboutSchool/AboutSchool";
import Infrastructure from "../components/Infrastructure/Infrastructure";
import JoinSession from "../components/JoinSession/JoinSession";
import Slider from "../components/slider/Slider";
import Birthday from "../components/BirthdayEvents/Birthday";
// import PrincipleDesk from "../components/PrincipleDesk/PrincipleDesk";
import Socialmedia from "../components/Socialmedia/Socialmedia";

import SchoolCard from "../components/SchoolCard/SchoolCard";
import Chairman from "../components/Chairman/Chairman";
import Qualifiedteacher from "../components/Chairman/Qualifiedteacher";
import NewsNotic from "../components/NewsNotic/NewsNotic";
import Latestevents from "../components/Latestevents/Latestevents";
import ScrollComponent from "../components/scrollTop";
import ScrollToTop from "react-scroll-to-top";
import "../styles/components/ScrollTop/ScrollTop.scss";
import { downArrow } from "@syncfusion/ej2-react-grids";
import SubjectWiseChart from "./SubjectWiseChart";
import Feedbacks from "../components/Feedbacks/Feedbacks";
import { Box } from "@mui/material";
import AlumniFeedbackSlider from "../components/AluminiFeedack/AlumniFeedbackSlider";

const Home = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <TopNav />
          <Navbar /> */}
          {/* <ScrollComponent /> */}
          <Slider />

          <Socialmedia />
          <Birthday />
          {/* <CardContents /> */}
          <SchoolCard />
          <Chairman />
          <Qualifiedteacher />
          <NewsNotic />
          <AboutSchool />
          <Infrastructure />
          {/* <PrincipleDesk /> */}

          <JoinSession />
          <AlumniFeedbackSlider />
          <Latestevents />
          <Feedbacks />

          {/* <SubjectWiseChart /> */}
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};
export default Home;
