import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, Typography, IconButton } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-next`}
      style={{
        ...style,
        display: "block",
        fontSize: "30px",
        position: "absolute",
        top: "50%",
        right: "-10px",
        transform: "translateY(-50%)",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <style>
        {`
          .slick-next::before {
            font-size: 30px !important;
            color: #f07b47!important;
          }
        `}
      </style>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-prev`}
      style={{
        ...style,
        display: "block",
        fontSize: "30px",
        position: "absolute",
        top: "50%",
        left: "-20px",
        transform: "translateY(-50%)",
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <style>
        {`
          .slick-prev::before {
            font-size: 30px !important;
            color: #f07b47 !important;
          }
        `}
      </style>
    </div>
  );
}
const AlumniFeedbackSlider = () => {
  const [list, setList] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    getlist();
  }, []);

  const getlist = async () => {
    try {
      const res = await get(urls.aluminiFeedback.list);
      const feedbacks = res.data.data.filter((f) => f.approved).slice(-6);
      setList(feedbacks.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowAll = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {" "}
        <h1>
          <div>
            <div>
              <span style={{ color: "#f07b47" }}> Alumni </span>
              Feedback{" "}
            </div>
            <div className="all-line1">
              <div className="first-line"></div>
              <div className="middle-line"></div>
              <div className="first-line last-line"></div>
            </div>
          </div>
        </h1>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          margin: "auto",
          padding: "20px",
        }}
      >
        <Slider {...sliderSettings}>
          {list.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "60px 20px",
                borderRadius: "12px",
              }}
            >
              <Box
                sx={{
                  padding: "20px",
                  borderRadius: "12px",
                  backgroundColor: "#fff",

                  width: "100%",
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",

                    top: "-40px",
                    left: "15%",
                    transform: "translateX(-50%)",
                    width: 0,
                    height: 0,
                    borderLeft: "30px solid transparent",
                    borderRight: "20px solid transparent",
                    borderBottom: "40px solid #fff",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "italic",
                    color: "#555",
                    marginTop: "20px",
                    textAlign: "center",
                    fontSize: "16px",
                    lineHeight: "1.6",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: expandedIndex === index ? 0 : 3,
                    height: expandedIndex === index ? "150px" : "80px",
                    transition:
                      "height 0.3s ease, -webkit-line-clamp 0.3s ease",
                  }}
                >
                  {item.feedback}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => toggleShowAll(index)}
                    sx={{
                      borderRadius: "50%",
                      background: "#fafafa",
                      "&:hover": {
                        background: "#fafafa",
                      },
                    }}
                  >
                    {expandedIndex === index ? (
                      <ExpandLessIcon fontSize="small" sx={{ color: "#000" }} />
                    ) : (
                      <ExpandMoreIcon fontSize="small" sx={{ color: "#000" }} />
                    )}
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#333",
                      textAlign: "right",
                      marginBottom: "5px",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#888",
                      textAlign: "right",
                      fontStyle: "italic",
                    }}
                  >
                    {item.currentPosition}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </>
  );
};

export default AlumniFeedbackSlider;
