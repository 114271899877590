import {
  Box,
  Button,
  Grid,
  styled,
  Paper,
  CardMedia,
  CardContent,
  Tooltip,
  InputLabel,
  Container,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";

import { useMediaQuery } from "@mui/material";
import Lottie from "react-lottie";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../FormInput";
import { LoadingButton } from "@mui/lab";

import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import animationData from "../../assets/FormSubmissions.json";
const InputLables = styled(InputLabel)(({}) => ({
  color: "#000",
  fontSize: "14px",
  paddingBottom: "10px",
}));
const Asterisk = styled("span")(({ theme }) => ({
  color: "red",
  marginLeft: "2px",
}));
const Papers = styled(Paper)(({}) => ({
  padding: "20px",
  marginBottom: "10px",
}));

const FormSubmit = ({ open }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {open && (
        <Box sx={{ backgroundColor: "white", height: "100vh", width: "100%" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Box
                sx={{
                  width: "300px",
                  height: "300px",
                }}
              >
                <Lottie options={defaultOptions} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                Thank You!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ textAlign: "center", mt: 2 }}
            >
              Your feedback has been submitted successfully.
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const AddFeedback = () => {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [formSubmit, setForSubmit] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      currentPosition: "",
      contactNumber: "",
      feedback: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name"),
      currentPosition: Yup.string().required().label("Current position"),
      contactNumber: Yup.number().required().label("Contact number").nullable(),
      feedback: Yup.string().required().label("Feedback").max(300),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setLoader(true);
      try {
        const res = await post(`${urls.aluminiFeedback.create}`, {
          name: values.name,
          currentPosition: values.currentPosition,
          feedback: values.feedback,
          contactNumber: values.contactNumber,
        });

        if (res.data.success) {
          formik.resetForm();
          setOpen(true);
          setLoader(false);
          setForSubmit(true);
        }
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    },
    validateOnBlur: false,
  });
  const { values, touched } = formik;

  const focused = {
    name: touched.name || values.name !== "",
    currentPosition: touched.currentPosition || values.currentPosition !== "",
    contactNumber: touched.contactNumber || values.contactNumber !== "",
    feedback: touched.feedback || values.feedback !== "",
  };

  const hasContent = {
    name: values.name !== "",
    currentPosition: values.currentPosition !== "",
    contactNumber: values.contactNumber !== "",
    feedback: values.feedback !== "",
  };

  return (
    <>
      {formSubmit ? (
        <FormSubmit open={open} />
      ) : (
        <Container maxWidth="md">
          <Box sx={{ padding: 2, backgroundColor: "whitesmoke" }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  <Box
                    sx={{
                      borderTop: "10px solid #0d47a1",
                      borderRadius: "5px",
                    }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        padding: "20px",
                        borderTop: "red",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ textAlign: "left", fontWeight: "bold" }}
                      >
                        Alumni Feedback Form
                      </Typography>
                      <Typography
                        id="modal-modal-title"
                        sx={{
                          textAlign: "left",
                          fontSize: "15px",
                          padding: "2px 0px",
                        }}
                      >
                        Form description
                      </Typography>
                      <Divider
                        orientation="horizontal"
                        sx={{
                          margin: "10px 0px",
                          padding: 0,
                          backgroundColor: "black",
                        }}
                      />
                      <Typography
                        id="modal-modal-title"
                        sx={{
                          textAlign: "left",
                          fontSize: "14px",
                          padding: "5px 0px",
                          color: "red",
                        }}
                      >
                        *Indicates Required
                      </Typography>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Papers
                    elevation={0}
                    sx={{
                      borderLeft:
                        focused.name || hasContent.name
                          ? "5px solid #0d47a1"
                          : "none",
                    }}
                  >
                    <InputLables>
                      Name <Asterisk>*</Asterisk>
                    </InputLables>
                    <FormInput
                      variant="standard"
                      placeholder="Enter your name"
                      size="small"
                      formik={formik}
                      name="name"
                    />
                  </Papers>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Papers
                    elevation={0}
                    sx={{
                      borderLeft:
                        focused.currentPosition || hasContent.currentPosition
                          ? "5px solid #0d47a1"
                          : "none",
                    }}
                  >
                    <InputLables>
                      Current position <Asterisk>*</Asterisk>
                    </InputLables>
                    <FormInput
                      placeholder="Enter your current position"
                      variant="standard"
                      size="small"
                      formik={formik}
                      name="currentPosition"
                    />
                  </Papers>
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <Papers
                    elevation={0}
                    sx={{
                      borderLeft:
                        focused.contactNumber || hasContent.contactNumber
                          ? "5px solid #0d47a1"
                          : "none",
                    }}
                  >
                    <InputLables>
                      Contact number <Asterisk>*</Asterisk>
                    </InputLables>
                    <FormInput
                      placeholder="Enter your contact number"
                      size="small"
                      variant="standard"
                      type="number"
                      formik={formik}
                      name="contactNumber"
                    />
                  </Papers>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Papers
                    elevation={0}
                    sx={{
                      borderLeft:
                        focused.feedback || hasContent.feedback
                          ? "5px solid #0d47a1"
                          : "none",
                    }}
                  >
                    <InputLables>
                      Feedback <Asterisk>*</Asterisk>
                    </InputLables>
                    <FormInput
                      size="small"
                      placeholder="Enter your feedback..."
                      multiline
                      variant="standard"
                      formik={formik}
                      name="feedback"
                    />
                  </Papers>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  columnGap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="medium"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </Button>
                <LoadingButton
                  size="small"
                  variant="standard"
                  loading={loader}
                  type="submit"
                  sx={{
                    color: "#ffff",
                    background: "#0d47a1",
                    ":hover": { background: "#0d47a1" },
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Container>
      )}
    </>
  );
};

export default AddFeedback;
