import * as React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { get } from "../../services/apis";
import { useState } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { Grid } from "@mui/material";
import "../../styles/components/LatestEvent/LatestEvent.scss";

const Latestevents = () => {
  const [eventss, setEvents] = useState([]);

  useEffect(() => {
    const events = async () => {
      const getEvents = await get(`/event/list/home-page`, {
        params: {
          search: {
            webView: "yes",
          },
          limit: 10,
          page: 1,
        },
      });
      if (getEvents.data.success) {
        setEvents(getEvents.data.result.data);
        console.log("video", setEvents(getEvents.data.result.data));
      }
    };
    events();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="latest-event-content">
      <h1>
        {/* <div >
          <div>
            <span style={{ color: "#f07b47" }}> Latest </span>
            Events
          </div>
          <div className="all-line1">
            <div className="first-line"></div>
            <div className="middle-line"></div>
            <div className="first-line last-line"></div>
          </div>
        </div> */}
        Latest Events
      </h1>

      <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Carousel responsive={responsive}>
            {eventss.map((e) => (
              <Card
                key={e._id}
                sx={{
                  maxWidth: 400,

                  margin: "0 auto",

                  boxSizing: "border-box",
                  marginBottom: "10px",
                }}
              >
                <div>
                  {e.video ? (
                    <ReactPlayer url={e.video} width="100%" height="250px" />
                  ) : e.image.link ? (
                    <img src={e.image.link} alt="" width="100%" height="250" />
                  ) : null}
                </div>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ height: "30px", overflow: "hidden" }}
                  >
                    {e.eventTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ height: "40px", overflow: "hidden" }}
                  >
                    {e.shortEvent}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link to={`/events-home/${e._id}`}>
                    <Button size="small">Read more</Button>
                  </Link>
                </CardActions>
              </Card>
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </div>
  );
};

export default Latestevents;
