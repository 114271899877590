import React, { useContext, useEffect, useState } from "react";
import "../../styles/components/Footer/Footer.scss";
import { Box, Button, Typography, styled } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { urls } from "../../services/urlConstant";
import { get } from "../../services/apis";
import { Slide } from "react-slideshow-image";
import DrawerComponent from "../navbar/Navbar-drawer";
import { Carousel, CarouselItem } from "react-material-ui-carousel";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import scanner from "../../assets/Images/MicrosoftTeams-image (33).png";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link as RouterLink } from "react-router-dom";

import InstagramIcon from "@mui/icons-material/Instagram";

import {
  useTheme,
  useMediaQuery,
  GridList,
  GridListTile,
  makeStyles,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ImageSearchSharp } from "@mui/icons-material";
import SettingContext from "../../context/SettingsContext";

const StyledLink = styled(RouterLink)({
  textDecoration: "none",
  color: "white",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  cursor: "pointer",
  transition: "1s",
  "&:hover": {
    color: " #564fff",
  },
});

const useStyles = makeStyles((theme) => ({
  gridList: {
    display: "flex",
    flexDirection: "row",
    // flexWrap: "wrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    // flexDirection:"row",
    alignItems: "center",

    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  url: {
    width: "500px",
    height: "300px",
    outline: "none",
  },
}));
const Footer = () => {
  const classes = useStyles();

  const { selectedSetting } = useContext(SettingContext);

  const [footerMessage, setFooterMessage] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [isPopUp, setIsPopUp] = useState(false);
  const [open, setOpen] = useState(0);
  const [active, setaAtive] = useState(0);
  const [image, setImage] = useState([]);
  const [addClass, updateClass] = useState(false);
  const [mega, setMega] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const contacts = [
    "Phone: +1 516 231 1313",
    "Mail: 0devdesign.studio@gmail.com",
    "Address: 3366 Jefferson Street, NEW HAMPTON, IA",
    "Working day: 9am - 5pm EST, Monday - Friday",
  ];

  const handlClikWeb = () => {
    try {
      let url = "https://md-95.webhostbox.net:2096";
      window.open(url, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const information = [
    {
      title: "Home",
      link: "/",
      children: [],
    },
    {
      title: "Admission",
      link: "/addmission",
      children: [],
    },
    {
      title: "Gallery",
      link: "/home-gallery",
      children: [],
    },

    {
      title: "Assignment",
      link: "/view-assignment",
      children: [],
    },
  ];
  const information2 = [
    {
      title: "Results",
      link: "/results",
      children: [],
    },
    {
      title: "Careers",
      link: "/home-careers",
      children: [],
    },
    {
      title: "Contact Us",
      link: "/contact-us",
      children: [],
    },
    { title: "Webmail", onClick: handlClikWeb, children: [] },
  ];

  const gallery = [
    "footer1.jpg",
    "footer2.jpg",
    "footer3.jpg",
    "footer4.jpg",
    "footer5.jpg",
    "footer6.jpg",
    "footer7.jpg",
    "footer8.jpg",
  ];
  function handleImageClick(value) {
    setOpen(true);
    setIsPopUp(image[value]);
    console.log("popup", image[value]);
  }
  const handleClose = () => {
    setOpen(false);
  };
  function handlePrevClick() {
    let selectindex = image.findIndex((img) => img === isPopUp);
    setIsPopUp(image[selectindex - 1]);
  }

  function handleNextClick() {
    let selectindex = image.findIndex((img) => img === isPopUp);
    setIsPopUp(image[selectindex + 1]);
  }

  const isFirstImage = open === 0;
  const isLastImage = open === image.length - 1;

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await get("/setting-footer/footer");
      const { success, data } = response.data;
      if (success) {
        const footerMessage = data[0].websiteFooter;
        const eCampusStreet =
          ' - <a href="https://ecampusstreet.com/" target="_blank"> Powered by eCampusStreet </a>';
        setFooterMessage(`${footerMessage}${eCampusStreet}`);
      }
    };

    fetchSettings();
  }, []);
  useEffect(() => {
    const contactPhone = async () => {
      const response = await get("/home-setting");
      const { success, data } = response.data;
      if (success) {
        const phone = data.phone;
        setContactPhone(`${phone}`);
      }
    };

    contactPhone();
  }, []);
  useEffect(() => {
    const gallery = async () => {
      const imagess = [];
      const response = await get("/gallery");
      const { success, data } = response.data;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].images.length; j++) {
          imagess.push(data[i].images[j].link);
        }
      }
      console.log("images", imagess);
      if (success) {
        const images = imagess.slice(0, 8);
        setImage(images);
      }
    };
    gallery();
  }, []);

  useEffect(() => {
    const contactEmail = async () => {
      const response = await get("/home-setting");
      const { success, data } = response.data;
      if (success) {
        const email = data.email;
        setContactEmail(`${email}`);
      }
    };

    contactEmail();
  }, []);
  useEffect(() => {
    const contactAddress = async () => {
      const response = await get("/home-setting");
      const { success, data } = response.data;
      if (success) {
        const address = data.address;
        setContactAddress(`${address}`);
      }
    };

    contactAddress();
  }, []);

  return (
    <footer>
      <div className="footer-flex-container">
        <div>
          {/* <h1>Contact Us</h1>
        <ul className="contact-list">
          {contacts.map((contact, index) => (
            <li key={index} className="contact">
              {contact}
            </li>
          ))}
          
        </ul> */}

          <h1>Contact Us</h1>
          <div className="contact-list">
            <div className="phoneI">
              <LocalPhoneIcon />
            </div>
            {contactPhone && (
              <ul
                className="phone"
                dangerouslySetInnerHTML={{ __html: contactPhone }}
              />
            )}
            <div className="emailI">
              <MailOutlineIcon />
            </div>
            {contactEmail && (
              <ul
                className="email"
                dangerouslySetInnerHTML={{ __html: contactEmail }}
              />
            )}
            <div className="addressI">
              <LocationOnIcon />
            </div>
            {contactAddress && (
              <ul
                className="address"
                dangerouslySetInnerHTML={{ __html: contactAddress }}
              />
            )}
          </div>
          <div style={{ paddingBottom: "5px" }}>
            {" "}
            <h6 style={{ fontWeight: 600 }}>Scan to get directions</h6>
            <img src={scanner} width={150} height={150} />
          </div>
        </div>
        <div>
          {/* <h1>Information</h1>
        <ul className="information-list">
          {information.map((information, index) => (
            <li key={index} className="information">
              {information}
            </li>
          ))}
        </ul> */}

          <div className="information-list">
            <div className="footermenu">
              {isMobile ? (
                <DrawerComponent menus={information} />
              ) : (
                <ul className={addClass ? "vissible" : "hidden"}>
                  {information.map((item, index) => {
                    return (
                      <li key={item.title}>
                        {item.link ? (
                          <NavLink to={`${item.link}`}>{item.title}</NavLink>
                        ) : (
                          <a>{item.title}</a>
                        )}
                        {/* {item.children.length > 0 ? (
                        <ul className={"mega " + (mega ? "mega__visible" : "")}>
                          {item.children.map((elements, index2) => {
                            return (
                              <li
                                key={elements.title}
                              >
                                <Link to={`${elements.link}`} >{elements.title}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )} */}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="footermenu2">
              {isMobile ? (
                <DrawerComponent menus={information2} />
              ) : (
                <ul className={addClass ? "vissible" : "hidden"}>
                  {information2.map((item, index) => {
                    return (
                      <li key={item.title}>
                        {item.link ? (
                          <NavLink to={`${item.link}`}>{item.title}</NavLink>
                        ) : (
                          <a onClick={item.onClick}>{item.title}</a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography
              sx={{
                mt: { lg: 12, md: 5, sm: 0 },
                display: "flex",
                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "center",
                },
              }}
              fontWeight={"bold"}
              fontSize={18}
            >
              FOLLOW US
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: 2,

                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "center",
                },
                gap: 2,
              }}
            >
              <StyledLink
                to={
                  "https://youtube.com/@excellentenglishmediumschool72?si=aosUo_XId1xpDWfS"
                }
                target="_blank"
              >
                <YouTubeIcon sx={{ fontSize: 30 }} />
              </StyledLink>
              <StyledLink to={""} target="_blank">
                <FacebookIcon sx={{ fontSize: 25 }} />
              </StyledLink>
              <StyledLink to={""} target="_blank">
                <InstagramIcon sx={{ fontSize: 25 }} />
              </StyledLink>
            </Box>
          </div>
        </div>
        <div>
          <h1>Our Gallery</h1>
          <div>
            <ul className="gallery-list">
              {/* {gallery.map((gallery, index) => (
            <li className="gallery">
              <img alt="" src={`/${gallery}`} key={index} />
            </li>
          ))} */}
              <GridList className={classes.gridlist} cols={4}>
                {image.map((url, index) => (
                  <GridListTile key={url} className="container" rows={0.5}>
                    <li className="gallery">
                      <img
                        key={index}
                        src={url}
                        alt={`Image ${index}`}
                        onClick={() => handleImageClick(index)}
                      />
                    </li>
                  </GridListTile>
                ))}
              </GridList>

              {isPopUp && (
                <Modal
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 5,
                  }}
                >
                  <div className="popup-image">
                    <div className="Licon">
                      <KeyboardDoubleArrowLeftIcon onClick={handlePrevClick} />
                    </div>
                    <img src={isPopUp} alt="asd" width="500px" height="300px" />
                    <div className="Ricon">
                      <KeyboardDoubleArrowRightIcon onClick={handleNextClick} />{" "}
                    </div>
                  </div>
                </Modal>
              )}
            </ul>
          </div>

          <div className="gallery-view-more-button">
            <Button href="/home-gallery">View More</Button>
          </div>
        </div>
      </div>
      <div className="foot">
        {footerMessage && (
          <p
            className="footer-message"
            dangerouslySetInnerHTML={{ __html: footerMessage }}
          />
        )}
      </div>{" "}
    </footer>
  );
};

export default Footer;
