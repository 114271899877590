import React from "react";
import "../../styles/components/NewsNotice/NewsNotice.scss";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState, useEffect } from "react";
import { urls } from "../../services/urlConstant";
import { get } from "../../services/apis";
import dayjs from "dayjs";
import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

const NewsNotic = () => {
  const [announceNotice, setAnounceNotice] = useState([]);
  const [awards, setAwards] = useState([]);

  const [scrollSpeed, setScrollSpeed] = useState(70);

  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(`${urls.announceNotice.getNotice}/home`, {
          params: {
            search: {
              webView: true,
            },
          },
        });
        const getNews = await get(`${urls.announceNews.getNews}/home`, {
          params: {
            search: {
              webView: "yes",
            },
          },
        });
        setAnounceNotice([...response.data.data, ...getNews.data.data]);
        const getAwards = await get(`/award/list/home-page`, {
          params: {
            search: {
              webView: "yes",
            },
          },
        });
        if (getAwards.data.success) {
          console.log();
          setAwards(getAwards.data.result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 120,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="news-and-notice-conatiner">
      <div className="news-awards">
        <div>
          <div>
            <h1>
              <span style={{ color: "#f07b47" }}> NEWS </span>AND
              <span style={{ color: "#f07b47" }}> NOTICE </span>
              <div className="all-line-3">
                <div className="first-line"></div>
                <div className="middle-line"></div>
                <div className="first-line last-line"></div>
              </div>
            </h1>
          </div>
        </div>
        <div style={{ padding: "100px 100px 50px 200px" }}>
          <Grid container spacing={12}>
            <Grid items xs={12} mg={6} lg={12}>
              <div className="news-container1" style={{ height: "433px" }}>
                <div
                  className="scroll-container1"
                  style={{ animationDuration: `${scrollSpeed}s` }}
                >
                  {announceNotice.map((news) => (
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={
                        news.news
                          ? `/home-news/${news._id}`
                          : `/home-notice/${news._id}`
                      }
                    >
                      <div className="scroll-text1">
                        <div className="news-content1">
                          <div className="news-date-month1">
                            <div className="date">
                              {dayjs(news.date).format("DD")}
                            </div>
                            <div className="month">
                              {dayjs(news.date).format("MMM")}
                            </div>
                          </div>
                          <div className="title-discription1">
                            <div style={{ height: "30px", overflow: "hidden" }}>
                              <h4>{news.title}</h4>
                            </div>
                            <div className="discription1">
                              {news.notice || news.news}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="achievents" item lg={12}>
        <div>
          <h1>
            <span style={{ color: "#f07b47" }}>AWARDS </span> AND
            <span style={{ color: "#f07b47" }}> ACHIEVEMENTS</span>
            <div className="all-line-4">
              <div className="first-line"></div>
              <div className="middle-line"></div>
              <div className="first-line last-line"></div>
            </div>
            -
          </h1>
        </div>
        <div style={{ margin: "15px 20px", padding: "50px 20px" }}>
          <Grid container spacing={2}>
            <Grid items lg={12} sm={8} md={4}>
              <Carousel responsive={responsive}>
                {awards.map((e) => (
                  <div key={e._id}>
                    <Card sx={{ maxWidth: 425 }} style={{ margin: "0px 10px" }}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={e.image ? e.image.link : ""}
                        alt="Latest Event"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          style={{ height: "30px", overflow: "hidden" }}
                        >
                          {e.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ height: "40px", overflow: "hidden" }}
                        >
                          {e.note}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link to={`/awards-home/${e._id}`}>
                          <Button size="small">More Info</Button>
                        </Link>
                      </CardActions>
                    </Card>
                  </div>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default NewsNotic;
