import React from "react";
import Fab from "@mui/material/Fab";
// import Box from "@mui/material/Box";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "../../styles/components/Socialmedia/Socialmedia.scss";
import { Link } from "@mui/material";

const Socialmedia = () => {
  return (
    <div className="social-item">
      <a href="https://youtube.com/@excellentenglishmediumschool72?si=aosUo_XId1xpDWfS">
        <Fab color="primary" aria-label="add">
          <YouTubeIcon />
        </Fab>
      </a>
    </div>
  );
};

export default Socialmedia;
